export const features = [
    {
      title: "Easily add, edit, and delete resources",
      description:
        "Admins can smoothly manipulate anywhere from ten to ten thousand custom, presented data points",
      videoPath: "./createProvider.mov",
      poster: "./mapInfo.png",
    },
    {
      title: "Create powerful search filters",
      description:
        "Our unique template builder enables visitors to intuitively search with fully controlled, customizable categories.",
      videoPath: "./mapWalkthrough.mov",
      poster: "./map2.png",
    },
    {
      title: "Integrate Mapscout with your website",
      description:
        "Mapscout is both web and mobile friendly and can be effortlessly added to existing sites.",
      videoPath: "./templateBuilder.mov",
      poster: "./map1.png",
    }
];