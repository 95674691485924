import LocalizedStrings from 'react-localization';

const localizationStrings = new LocalizedStrings({
  en: {
    // auth page text
    emailLabel: 'Email address',
    emailPlaceholder: 'Enter email',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Password',
    confirmPasswordLabel: 'Confirm Password',
    login: 'Login',
    signUp: 'Sign up',
    forgotPassword: 'Forgot your password?',
    resetPassword: 'Reset password',
    submit: 'Next',
    sent: 'Password reset email sent',
    // nav bar text
    home: 'Home',
    facilityUpload: 'Facility Upload',
    admin: 'Admin',
    adminLogin: 'Admin Login',
    signOut: 'Sign Out',
    searchProviderName: 'Search provider name',
    searchZipcode: 'Search zipcode',
    hideLabel: 'Hide',
    showLabel: 'Show',
    languagesLabel: 'Languages',
    agesLabel: 'Ages',
    insuranceLabel: 'Insurance',
    serviceTypeLabel: 'Service Type',
    specializationsLabel: 'Specializations',
    therapyTypeLabel: 'Therapy Types',
    filters: 'Filters',
    moreFilters: 'More Filters',
    lessFilters: 'Less Filters',
    createAccount: 'Create Account',
    create: 'Create',
    orgNamePlaceholder: 'Organization name',
    orgURLPlaceholder: 'Organization URL',
    mapScoutURLPlaceholder: 'mapscout.io/'
  },
  es: {
    // auth page text
    emailLabel: 'Dirección de correo electrón',
    emailPlaceholder: 'Ingrese correo electrónico',
    passwordLabel: 'Contraseña',
    passwordPlaceholder: 'Contraseña',
    login: 'Iniciar sesión',
    signUp: 'Inscribirse',
    forgotPassword: 'Olvidaste tu contraseña',
    resetPassword: 'Restablecer la contraseña',
    submit: 'Siguiente',
    sent: 'correo electrónico de restablecimiento de contraseña enviado',
    // nav bar text
    home: 'Casa',
    facilityUpload: 'Facilidad de carga',
    admin: 'Administrador',
    adminLogin: 'Inicio de sesión de administrador',
    signOut: 'Cerrar  sesión',
    searchProviderName: 'Nombre del proveedor de búsqueda',
    searchZipcode: 'Buscar código postal',
    hideLabel: 'Esconder',
    showLabel: 'Mostrar',
    languagesLabel: 'Idiomas',
    agesLabel: 'Edad',
    insuranceLabel: 'Seguro',
    serviceTypeLabel: 'Tipo de servicio',
    specializationsLabel: 'Especializaciones',
    therapyTypeLabel: 'Tipos de terapia',
    filters: 'Filtros',
    moreFilters: 'Mas filtros',
    lessFilters: 'Menos filtros',
    createAccount: 'Crear Cuenta',
    create: 'Crear',
  },
  zh: {
    // auth page text
    emailLabel: '邮箱地址',
    emailPlaceholder: '输入邮箱地址',
    passwordLabel: '密码',
    passwordPlaceholder: '密码',
    login: '登陆',
    signUp: '注册',
    forgotPassword: '忘记你的密码？',
    resetPassword: '重设密码',
    next: '下一个',
    sent: '密码重置电子邮件已发送',
    // nav bar text
    home: '主页',
    facilityUpload: '设备上传',
    admin: '管理人员登陆',
    adminLogin: '管理人员登陆',
    signOut: '退出登录',
    searchProviderName: '搜素供应商名称',
    searchZipcode: '搜索邮编',
    hideLabel: '隐藏',
    showLabel: '展示',
    languagesLabel: '语言',
    agesLabel: '年龄',
    insuranceLabel: '保险',
    serviceTypeLabel: '服务类型',
    specializationsLabel: '专项研究',
    therapyTypeLabel: '疗法',
    filters: '选条件',
    moreFilters: '增加筛选条件',
    lessFilters: '减少筛选条件',
    createAccount: '创建帐号',
    create: '创造',
  },
});

export default localizationStrings;
