/* eslint-disable global-require */
import { MarkerProps } from './types';

const markersConfig: MarkerProps[] = [
  {
    name: 'Adib Khan',
    image: require('../../assets/img/team/adib.jpg'),
    role: 'Engineering Manager',
    lat: 33.7756,
    lng: -84.3963,
  },
  {
    name: 'Allan Nguyen',
    image: require('../../assets/img/team/allan.png'),
    role: 'Developer',
    lat: 33.77443,
    lng: -84.39422,
  },
  {
    name: 'Ameya Vitankar',
    image: require('../../assets/img/team/ameya.png'),
    role: 'Developer',
    lat: 33.777,
    lng: -84.39633,
  },
  {
    name: 'Eric Wang',
    image: require('../../assets/img/team/eric.jpg'),
    role: 'Product Manager',
    lat: 33.7754,
    lng: -84.3958,
  },
  {
    name: 'Jason Lee',
    image: require('../../assets/img/team/jason.jpg'),
    role: 'Developer',
    lat: 33.7773,
    lng: -84.39653,
  },
  {
    name: 'Luke Kim',
    image: require('../../assets/img/team/luke.jpg'),
    role: 'Developer',
    lat: 33.77654,
    lng: -84.397,
  },
  {
    name: 'Sharath Palathingal',
    image: require('../../assets/img/team/sharath.jpg'),
    role: 'Developer',
    lat: 33.7744,
    lng: -84.397,
  },
  {
    name: 'Stephanie He',
    image: require('../../assets/img/team/stephanie.png'),
    role: 'Product Designer',
    lat: 33.775,
    lng: -84.3939,
  },
  {
    name: 'Vince Li',
    image: require('../../assets/img/team/vince.png'),
    role: 'Developer',
    lat: 33.77446,
    lng: -84.3975,
  },
  {
    name: 'Jakob Johnson',
    image: require('../../assets/img/team/jakob.png'),
    role: 'Developer',
    lat: 33.77446,
    lng: -84.3989,
  },
  {
    name: 'Jeff Zhan',
    image: require('../../assets/img/team/jeff.png'),
    role: 'Developer',
    lat: 33.7752,
    lng: -84.398,
  },
  {
    name: 'Kris Satya',
    image: require('../../assets/img/team/kris.png'),
    role: 'Developer',
    lat: 33.7756,
    lng: -84.3974,
  },
  {
    name: 'Lily Yang',
    image: require('../../assets/img/team/lily.png'),
    role: 'Product Designer',
    lat: 33.774,
    lng: -84.396,
  },
  {
    name: 'Yuhan Zhou',
    image: require('../../assets/img/team/yuhan.jpg'),
    role: 'Product Designer',
    lat: 33.774123,
    lng: -84.3955,
  },
  {
    name: 'Rahul Tholakapalli',
    image: require('../../assets/img/team/rahul.jpg'),
    role: 'Developer',
    lat: 33.7755,
    lng: -84.3982,
  },
  {
    name: 'Sam Shapiro',
    image: require('../../assets/img/team/sam.jpg'),
    role: 'Product Community Manager',
    lat: 33.77654,
    lng: -84.394,
  },
  {
    name: 'Ben Holmes',
    image: require('../../assets/img/team/ben.jpg'),
    role: 'Engineering Mentor',
    lat: 33.7754,
    lng: -84.3951,
  },
  {
    name: 'James Wang',
    image: require('../../assets/img/team/james.png'),
    role: 'Mentor',
    lat: 33.776,
    lng: -84.395,
  },
  {
    name: 'Charlie Ye',
    image: require('../../assets/img/team/charlie.png'),
    role: 'Senior Product Manager',
    lat: 33.7759,
    lng: -84.393,
  },
  {
    name: 'Jason Yang',
    image: require('../../assets/img/team/jasonY.png'),
    role: 'Product Design Mentor',
    lat: 33.77627,
    lng: -84.398,
  },
  {
    name: 'Huan Deng',
    image: require('../../assets/img/team/huan.png'),
    role: 'Product Design Mentor',
    lat: 33.77642,
    lng: -84.3985,
  },
  {
    name: 'Daniel Hsing',
    image: require('../../assets/img/team/daniel.jpg'),
    role: 'Engineering Mentor',
    lat: 33.7768,
    lng: -84.395,
  },
  {
    name: 'Max Karpawich',
    image: require('../../assets/img/team/max.jpg'),
    role: 'Engineering Mentor',
    lat: 33.77682,
    lng: -84.3954,
  },
];

export default markersConfig;