export const ourMaps = [
    {
      title: "The Philadelphia Alliance for Child Trauma Services (PACTS)",
      description: "PACTS is a network of child serving systems and organizations that provides evidence-based practices for traumatized youth and their families in the greater Philadelphia area.",
      image: "./map2.png"
    },
    {
      title: "ClinWiki",
      description: "A 501(c)(3) focused on making clinical trials more transparent and approachable in order to drive participation and faster progress against serious diseases.",
      image: "./cliniwiki.png"
    }
];